<template>
  <div class="service-times-presentations template-1">
    <page-header v-if="allowPage" class="mx-lg">
      <h1>{{ translations.tcChurchVisits }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <div v-if="!allowPage">
      <page-header class="mx-lg">
        <h1>{{ translations.tcChurchVisits }}</h1>
      </page-header>
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <page-body class="mx-lg">
        <section class="section-1">
          <div class="bg-block p-4">
            <header class="mb-5">
              <h2>{{ translations.tcVisitNotes }}</h2>
              <ToggleArrow
                v-if="toggle == true"
                @click="Section1 = !Section1"
                section="Section1"
                :status="Section1"
              ></ToggleArrow>
            </header>
            <b-button v-if="Section1 && iCanSeeAny(secured_note_add_controls)" variant="primary" @click="addVisit()">{{
              translations.tcAddVisitNote
            }}</b-button>
            <b-button
              v-if="Section1 && convertedVisits.length > 6"
              class="ml-3"
              variant="primary"
              @click="showAll = !showAll"
            >
              <span v-if="!showAll">{{ translations.tcViewAllNotes }}</span>
              <span v-if="showAll">{{ translations.tcHideExtraNotes }}</span>
            </b-button>
            <div v-if="Section1 && translations.components" class="pres-dates d-flex flex-wrap">
              <div class="col col-4 mb-3" v-for="(note, index) in this.convertedVisits" :key="note.nte_key">
                <app-event
                  :obj="note"
                  :i18n="translations.components"
                  v-if="index < 6 || showAll"
                  @edit_requested="onEditClick($event)"
                >
                </app-event>
              </div>
            </div>
          </div>
        </section>
      </page-body>
      <page-footer class="mx-lg">
        <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
      </page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import dateData from '@/json/date.json'
import Event from '@/components/Event.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-visits-sc',
  mixins: [translationMixin],
  data() {
    return {
      backJumpObj: {
        link: '#',
        location: '',
      },
      Section1: true,
      secured_note_add_controls: {
        add_church_visit_note_button: '5e3dec43-8a2e-4c16-a09c-b00c1c6a5a80',
      },
      showAll: false,
      toggle: true,
      translations: {},
      visits: [],
    }
  },
  async created() {
    try {
      await this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('back-jump', 'quick-jump', 'event', 'security-banner'),
      ]).then((results) => {
        const translatedText = { ...results[1] }
        this.$set(this.translations, 'components', translatedText)
      })
      await this.pageLoad()
    } catch (e) {
      console.error(e)
    } finally {
      await this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearSingleVisit: 'churchMinistry/clearSingleVisit',
      loadChurchDetail: 'churchMinistry/loadChurchDetail',
      loadAllVisitsByChurch: 'churchMinistry/loadAllVisitsByChurch',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadChurchDetail(),
          await this.loadAllVisitsByChurch({ church_key: this.userSelectedChurchKey }),
        ]).then(() => {
          this.backJumpObj.location = this.churchDetail.name
          this.backJumpObj.link = '/programs/cm/church-profile'
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addVisit() {
      await Promise.all([await this.clearSingleVisit(), await this.setSelectedVisitKey(null)]).then(() => {
        this.setRouterBackLink(this.$route.name)
        this.$router.push('/programs/cm/churchProfile/add-church-visit')
      })
    },
    async onEditClick(data) {
      await this.setSelectedVisitKey(data.nte_key)
      this.setRouterBackLink(this.$route.name)
      this.$router.push('/programs/cm/churchProfile/view-church-visit')
    },
  },
  computed: {
    ...mapGetters({
      all_visits_by_church: 'churchMinistry/all_visits_by_church',
      churchDetail: 'churchMinistry/churchDetail',
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path.substr(0, 26))
    },
    convertedVisits() {
      return this.all_visits_by_church.map((obj) => {
        let cDate = !!obj.contact_date ? new Date(obj.contact_date) : null
        let monthList = dateData.months.map((month) => month)
        let monthMatch = !!cDate ? monthList.find((m) => m.value * 1 === cDate.getMonth() + 1) : null
        let month = !!monthMatch ? monthMatch.abbrlow : ''
        let monthAbbrGuid = !!monthMatch ? monthMatch.guidAbbr : ''
        let day = !!cDate ? cDate.getDate() : ''
        let year = !!cDate ? cDate.getFullYear() : ''
        let fullDate = obj.contact_date

        return {
          nte_key: obj.nte_key,
          contact_date: obj.contact_date,
          nte_ctt_key: obj.nte_ctt_key,
          contact_type: obj.contact_type,
          nte_contact_by_ind_key: obj.nte_contact_by_ind_key,
          name: obj.name,
          sort_name: obj.sort_name,
          nte_note: obj.nte_note,
          month: month,
          monthAbbrGuid: monthAbbrGuid,
          day: day,
          year: year,
          fullDate: fullDate,
          editLink: true,
        }
      })
    },
  },
  components: {
    appEvent: Event,
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 40px;
    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px 20px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
      h2 {
        flex: 1 1 auto;
        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }
      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.event {
  margin-bottom: 0;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
